import { ReactNode, FC, useState } from "react";
import { AppBar, Box, Toolbar, Theme, IconButton, useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { theoryAreaState, theoryChapterState } from "state/theoryState";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import { styleRoundedBottom } from "theme/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HeaderDesktopMenu } from "../headerDesktopMenu";
import { useAppIntl } from "services/useAppIntl";
import { SideMenu } from "utils/helpers/theory/sideMenu";
import { Icon, iconMenu } from "theme/icons";

interface IHeaderProps {
  children?: ReactNode;
}

export const Header: FC<IHeaderProps> = ({ children }) => {
  const { areaId, chapterId } = useParams();
  const intl = useAppIntl();
  const theme = useTheme();
  const area = useRecoilValue(theoryAreaState);
  const chapter = useRecoilValue(theoryChapterState);
  const title = chapterId && chapter ? chapter.title : area?.title;
  const topTitle = chapterId ? `/ ${area?.title}` : "";
  const routes = useRouteConfiguration();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const theoryTitle = intl.formatMessage({ id: "theory" });
  const [showSideMenu, setShowSideMenu] = useState(false);

  const navigateBack = () => {
    if (!areaId) return navigate(-1);

    // Navigate back up in the tree
    let path = chapterId ? routes.theoryChapters.path.replace(":areaId", areaId!) : routes.theory.path;

    // Always navigate to theory if on desktop
    if (!isDesktop) path = routes.theory.path;

    navigate(path);
  };

  return (
    <AppBar position="fixed" elevation={0} sx={[styleRoundedBottom, { backgroundColor: "white" }]}>
      <Toolbar sx={{ backgroundColor: theme.palette.primary.main }}>
        <HeaderNavigation
          iconSize={"large"}
          topTitle={`${theoryTitle} ${topTitle}`}
          title={title ? title : ""}
          onNavigate={navigateBack}
        />
        {isDesktop && (
          <>
            <HeaderDesktopMenu />
            <Box width="30%" textAlign="right">
              <IconButton onClick={() => setShowSideMenu(!showSideMenu)} sx={{ color: "#FFFFFF" }}>
                <Icon name={iconMenu} />
              </IconButton>
              <SideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
            </Box>
          </>
        )}
        {/* <Box width="30%" /> */}
      </Toolbar>
      {children}
    </AppBar>
  );
};
